import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import {
  HubHeader,
  HubFooter,
  SEO,
  About,
  Faq,
  Terms,
  Partnerships,
  Speakers,
  Sponsor,
  Agendas,
  Visa
} from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { gridContainer, fullWidth, H5, H4, P, defaultColors, breakpoints } from 'styles';
import { useEventData } from 'hooks';

const Home = ({ data }) => {
  const {
    siteName,
    scrollTo,
    registerRef,
    theme,
    setSelectedEvent,
    setRegistrationFormSubmissionStatus,
    registrationFormSubmissionStatus,
    returningVisitorsUnverifiedEmailAddress,
    setReturningVisitorsUnverifiedEmailAddress
  } = useContext(LocalContext);
  const { user, loading, firebase } = useContext(FirebaseContext);
  const [verificationEmailResent, setVerificationEmailResent] = useState(false);
  const { edges: events } = useEventData();
  const { frontmatter: event } = data.markdownRemark;

  useEffect(() => {
    setSelectedEvent({
      eid: event.eid,
      name: event.name,
      colors: event.colors,
      slug: event.slug
    });
  }, []);

  useEffect(() => {
    if (user?.eventsUserCanAccess?.includes('2')) {
      navigate(`/events/elevate-your-digital-sales-expand-your-online-reach/livestream`);
    }
  }, [user]);

  useEffect(() => {
    if (!loading && firebase) {
      if (registrationFormSubmissionStatus.submitted && firebase.auth?.currentUser) {
        // This block is triggered after somone fills in the registration form, verifies their email
        // address in a new tab of the same browser, and then clicks on the old tab in the same
        // browser. We just reset all appropriate state and reload firebase.auth.currentUser,
        // which in turn triggers an update of 'user' state in the old tab.
        setRegistrationFormSubmissionStatus({
          submitting: false,
          submitted: false
        });
        if (returningVisitorsUnverifiedEmailAddress) {
          setReturningVisitorsUnverifiedEmailAddress(null);
        }
        firebase.auth.currentUser.reload();
      } else if (
        !registrationFormSubmissionStatus.submitted &&
        verificationEmailResent &&
        firebase.auth?.currentUser
      ) {
        // This block is triggered when somone verifies their email address in another tab of the
        // same browser after a verification email is resent to them, and then they click on the old
        // tab. We just reset all appropriate state and reload firebase.auth.currentUser, which in
        // turn triggers an update of 'user' state in the old tab.
        setReturningVisitorsUnverifiedEmailAddress(null);
        setVerificationEmailResent(false);
        firebase.auth.currentUser.reload();
      } else if (!firebase.auth?.currentUser) {
        // This block is first triggered the first time a visitor lands on the registration page, in
        // which case '_returningVisitorsUnverifiedEmail' will always be false and the rest of the
        // block won't run.
        // The block is also triggered if a visitor fills in the registration form and then
        // refreshes or revisits the page later without verifying their email address first, in
        // which case we resend them the verification email.
        // Finally, it's also triggered if a visitor fills out the registration form in one browser,
        // verifies in another browser, and then for whatever reason returns to the original browser
        // later. In this case, `newUserEmail-${siteName}` will still be stored in the original
        // browser's localStorage, so we want to check if a user account already exists for that
        // email. If so, we remove `newUserEmail-${siteName}` from localStorage and reset all
        // appropriate state.
        const _returningVisitorsUnverifiedEmailAddress = JSON.parse(
          window.localStorage.getItem(`newUserEmail-${siteName}`)
        );
        if (_returningVisitorsUnverifiedEmailAddress) {
          setReturningVisitorsUnverifiedEmailAddress(_returningVisitorsUnverifiedEmailAddress);
          try {
            firebase.registration
              .checkIfAccountAlreadyExists({
                email: _returningVisitorsUnverifiedEmailAddress
              })
              .then(async ({ data: emailAddressVerifiedInAnotherBrowser }) => {
                if (emailAddressVerifiedInAnotherBrowser) {
                  window.localStorage.removeItem(`newUserEmail-${siteName}`);
                  setReturningVisitorsUnverifiedEmailAddress(null);
                  setRegistrationFormSubmissionStatus({
                    submitting: false,
                    submitted: false
                  });
                } else if (
                  !emailAddressVerifiedInAnotherBrowser &&
                  !registrationFormSubmissionStatus.submitted &&
                  !verificationEmailResent
                ) {
                  await firebase.emails.resendVerificationEmail({
                    email: _returningVisitorsUnverifiedEmailAddress
                  });
                  setVerificationEmailResent(true);
                }
              });
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  }, [loading, firebase]);

  return (
    <>
      <SEO pageSpecificTitle="Elevate Your Digital Sales, Expand Your Online Reach" />
      <HubHeader
        event={event}
        scrollToRegister={() => scrollTo(registerRef)}
        colors={defaultColors}
        events={events}
      />
      {/* <Sponsor /> */}
      <Agendas
        agendas={event.agendas}
        colors={event.colors}
        eid={event.eid}
        breakouts={event.breakouts}
      />
      <SpeakersContainer $theme={theme}>
        <Title theme={theme}>Your Host and Keynote Speakers</Title>
        <Speakers speakers={event.speakers} colors={event.colors} id="speakers" />
      </SpeakersContainer>
      <About />
      <Visa />
      <Section id="faq" theme={theme}>
        <Title theme={theme}>Frequently Asked Questions</Title>
        <FAQContainer theme={theme}>
          <Faq />
        </FAQContainer>
      </Section>
      <Partnerships />
      <Terms />
      <HubFooter />
    </>
  );
};

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: { slug: { eq: "elevate-your-digital-sales-expand-your-online-reach" } }
    ) {
      id
      frontmatter {
        name
        slug
        status
        eid
        colors {
          primary
          secondary
          tertiary
        }
        title
        subtitle
        banner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        mobileBanner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
          }
        }
        breakouts {
          id
          title
          url
          text
        }
        speakers {
          id
          name
          position
          fbLink
          twLink
          linkedinLink
          emailLink
          shortSummary
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        agendas {
          day
          title
          slots {
            orderNumber
            time
            title
            speaker
            multipleSpeakers {
              speakerNumber
              link
              speaker
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            speakerImages {
              imageOrder
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        description
        date
        start
        end
        location
        startTime
        endTime
      }
    }
  }
`;

const SpeakersContainer = styled.section`
  ${gridContainer}
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  width: 100%;
  ${H5} {
    margin: 0 auto;
    text-align: center;
    color: ${({ $theme }) => $theme.primary};
  }
  p {
    color: ${({ $theme }) => $theme.primary};
  }
  ${breakpoints.sm} {
    padding: 3rem 1.25rem;
  }
  ${breakpoints.lg} {
    padding: 6rem 0;
    grid-column: 1/8;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
    padding: 6rem 0;
  }
`;

const Section = styled.section`
  background-color: 'transparent';
  color: ${({ theme }) => theme.primary};
  ${gridContainer}
  ${H4} {
    grid-column: 1/7;
  }
  ${P} {
    grid-column: 1/7;
  }
  button {
    grid-column: 1/7;
    margin: 0 auto;
  }
  padding: 3rem 1rem;
  @media only screen and (min-width: 768px) {
    padding: 6rem 1.25rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 6rem 0;
    ${H4} {
      grid-column: 2/12;
    }
    ${P} {
      grid-column: 2/12;
    }
    button {
      grid-column: 2/12;
    }
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
    padding: 6rem 0;
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.primary};
  font-family: 'droid-sans', sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  text-align: center;
  ${fullWidth}
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const FAQContainer = styled.div`
  align-items: start;
  color: ${({ theme }) => theme.primary};
  grid-column: 1/7;
  justify-content: center;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

export default Home;
